<template>
    <div class="fulfillment">
        <fulfillment-hero class="hero" />
        <div class="container">
            <div class="sticky-wrapper">
                <div class="hidden-top-side"></div>
                <div class="mt-5 form" id="fullfilment-form">
                    <fulfillment-form class="fulfillment-form" />
                </div>
                <div class="sections column">
                    <div class="section mt-5">
                        <div class="title mb-3">Conoce Zubut Fulfillment</div>
                        <div class="subtitle mb-3">
                            Recepción, almacenaje, preparación y envío de pedidos para tu eCommerce
                        </div>
                        <div class="subtitle">
                            Si tu negocio está en crecimiento constante, contratar un servicio de
                            fulfillment puede ser la solución para seguir manteniendo el orden y
                            clientes satisfechos.
                        </div>
                        <div class="d-flex flex-wrap justify-content-between">
                            <fulfillment-service
                                class="service mt-4"
                                title="Entregas locales operadas por nosotros"
                                img="img-zf-01"
                            />
                            <fulfillment-service
                                class="service mt-4"
                                title="Almacenaje y control de inventarios a la medida"
                                img="img-zf-02"
                            />
                            <fulfillment-service
                                class="service mt-4"
                                title="Ubicación estratégica en Cdmx"
                                img="img-zf-03"
                            />
                            <fulfillment-service
                                class="service mt-4"
                                title="Seguimiento y entrega"
                                img="img-zf-04"
                            />
                            <fulfillment-service
                                class="service mt-4"
                                title="Operaciones los 365 días del año"
                                img="img-zf-05"
                            />
                            <fulfillment-service
                                class="service mt-4"
                                title="Control de seguridad"
                                img="img-zf-06"
                            />
                            <fulfillment-service
                                class="service mt-4"
                                title="Pick and Pack personalizado de tus productos"
                                img="img-zf-07"
                            />
                            <fulfillment-service
                                class="service mt-4"
                                title="Envíos a toda la República Mexicana"
                                img="img-zf-08"
                            />
                        </div>
                    </div>
                    <div class="section mt-5">
                        <div class="title">Empresas que nos confían sus entregas</div>

                        <div class="row mt-4 container">
                            <div class="company-image-container">
                                <z-picture
                                    img="logo-liverpool.png"
                                    alt="logo-liverpool"
                                    className="w-100 h-100"
                                />
                            </div>
                            <div class="company-image-container">
                                <z-picture
                                    img="logo-sigma.png"
                                    alt="logo-sigma"
                                    className="w-100 h-100"
                                />
                            </div>
                            <div class="company-image-container">
                                <z-picture
                                    img="logo-farmacias.png"
                                    alt="logo-farmacias"
                                    className="w-100 h-100"
                                />
                            </div>
                            <div class="company-image-container">
                                <z-picture
                                    img="logo-paulette.png"
                                    alt="logo-paulette"
                                    className="w-100 h-100"
                                />
                            </div>
                            <div class="w-100 mt-4"></div>
                            <div class="company-image-container">
                                <z-picture
                                    img="logo-dportnis.png"
                                    alt="logo-dportnis"
                                    className="w-100 h-100"
                                />
                            </div>
                            <div class="company-image-container">
                                <z-picture
                                    img="logo-viviri.png"
                                    alt="logo-viviri"
                                    className="w-100 h-100"
                                />
                            </div>
                            <div class="company-image-container">
                                <z-picture
                                    img="logo-vinos.png"
                                    alt="logo-vinos"
                                    className="w-100 h-100 h-100"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="section mt-5">
                        <div class="title">Ventajas con Zubut</div>
                        <div class="d-flex mt-3">
                            <z-icon class="icon" name="Pin" />
                            <div>
                                <div class="subtitle pl-3 mb-2">
                                    Presencia en las ciudades con más tráfico de eCommerce en México
                                </div>
                                <div class="cities pl-3 d-flex ">
                                    <div>GUADALAJARA</div>
                                    <div>CDMX</div>
                                    <div>MONTERREY</div>
                                    <div>QUERÉTARO</div>
                                    <div>LEÓN</div>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex mt-3">
                            <z-icon class="icon" name="Management" />
                            <div class="subtitle pl-3">
                                Tecnología de administración de inventarios y envíos
                            </div>
                        </div>
                        <div class="d-flex mt-3">
                            <z-icon class="icon" name="Truck" />
                            <div class="subtitle pl-3">
                                Todas las entregas locales operadas por nosotros
                            </div>
                        </div>
                        <div class="d-flex mt-3">
                            <z-icon class="icon" name="Support" />
                            <div class="subtitle pl-3">Atención personalizada y flexible</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mt-5">
                <div class="title">Temas que podrían interesarte</div>
                <div class="d-flex justify-content-between flex-wrap my-4">
                    <blog-post
                        v-for="(blog, index) in blogPosts"
                        :key="blog.title"
                        :index="index"
                        :title="blog.title"
                        :date="blog.date"
                        :image="blog.image"
                        :link="blog.link"
                        :type="blog.type"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import FulfillmentHero from "@/components/FulfillmentHero";
import FulfillmentService from "@/components/FulfillmentService";
import FulfillmentForm from "@/components/FulfillmentForm";
import BlogPost from "@/components/BlogPost";
import ZIcon from "@/components/ZIcon";
import ZPicture from "@/components/ZPicture.vue";

export default {
    name: "Fulfillment",
    components: {
        FulfillmentHero,
        FulfillmentService,
        FulfillmentForm,
        BlogPost,
        ZIcon,
        ZPicture
    },
    data() {
        return {
            blogPosts: [
                {
                    title: "Tendencias del servicio al cliente en eCommerce este 2022",
                    date: "24 de Mayo, 2022",
                    image: "tendencias-2022.jpeg",
                    link:
                        "https://blog.zubut.com/blog/2022-05-24-tendencias-del-servicio-al-cliente-en-ecommerce-este-2022/",
                    type: 0
                },
                {
                    title: "Kueski Pay: compra ahora, paga después",
                    date: "20 de Abril, 2022",
                    image: "kueskipay.jpeg",
                    link:
                        "https://blog.zubut.com/blog/2022-04-20-kueski-pay-compra-ahora-paga-despues/",
                    type: 0
                },
                {
                    title: "Guía para tener éxito en tus ventas eCommerce este Hot Sale",
                    date: "04 de Abril, 2022",
                    image: "guia-hot-sale.jpeg",
                    link:
                        "https://blog.zubut.com/blog/2022-04-04-guia-para-tener-exito-en-tus-ventas-ecommerce-este-hot-sale/",
                    type: 0
                }
            ]
        };
    }
};
</script>

<style lang="scss" scoped>
.sticky-wrapper {
    display: grid;
    grid-template-areas: "hidden-top-side" "form" "sections";
}
.hero {
    position: absolute;
}
.hidden-top-side {
    grid-area: hidden-top-side;
    height: 75vh;
}
.sections {
    grid-area: sections;
}
.fulfillment {
    position: relative;
    text-align: left;

    .service {
        font-size: 1.2rem;
        font-weight: bold;
        width: 48%;
    }

    .title {
        font-size: 28px;
        font-weight: bold;
    }

    .subtitle {
        font-weight: 500;
    }

    .icon {
        margin-top: 5px;
        min-width: 24px;
        text-align: left;
    }

    .section {
        margin-bottom: 120px;
    }

    .company-image-container {
        max-width: 25%;
        height: 25%;
    }

    .cities {
        color: $comet;
        font-weight: bold;
        font-size: 0.77rem;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    @include media-breakpoint-down(lg) {
        .cities {
            justify-content: flex-start;
            div {
                padding-right: 20px;
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .column {
            width: 64%;
        }
    }

    .form {
        grid-area: form;
        top: 120px;
        align-self: flex-start;
        position: sticky;
    }

    @include media-breakpoint-down(lg) {
        .form {
            position: static;
        }
    }

    @media (min-width: 1200px) {
        .sticky-wrapper {
            grid-template-areas: "hidden-top-side form" "sections form";
        }
        .container {
            max-width: 1400px;
        }
    }
}
</style>
