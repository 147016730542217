<template>
    <div class="fulfillment-form">
        <div class="title mb-2">
            Regístra tu negocio
        </div>
        <p>
            Conoce todo lo que Zubut puede hacer por tu negocio
        </p>
        <z-input
            class="form-input"
            v-model="firstName"
            label="Nombre"
            type="text"
            :state="validFirstName"
            id="name-input"
        />
        <z-input
            class="form-input"
            v-model="lastName"
            label="Apellido"
            type="text"
            :state="validLastName"
            id="name-input"
        />
        <z-input
            class="form-input"
            v-model="phone"
            label="Teléfono / Celular"
            type="tel"
            :state="validPhone"
            id="phone-input"
        />
        <z-input
            class="form-input"
            v-model="companyName"
            label="Nombre de la compañía"
            type="text"
            :state="validCompanyName"
            id="company-input"
        />
        <z-select
            v-model="companyType"
            :state="validateCompanyType"
            :options="options"
            label="Tipo de compañía"
        />
        <z-select
            v-model="avgDeliveries"
            :state="validateAvgDeliveries"
            :options="avgDeliveriesOptions"
            label="Numero de pedidos prom. al mes"
        />
        <z-input
            v-if="isECommerce"
            class="form-input"
            v-model="website"
            label="Sitio web (opcional)"
            type="text"
            :state="validWebsite"
            id="web-site-input"
        />
        <z-select
            v-model="salesChannel"
            :options="salesChannelOptions"
            :state="validateSalesChannel"
            label="Canal de Venta"
        />
        <z-select
            v-model="conversionChannel"
            :options="conversionChannelOptions"
            :state="validateConversionChannel"
            label="¿Dónde escuchaste de nosotros?"
        />
        <z-button
            class="form-input form-button mt-4"
            :class="{
                submitted: submitted,
                error: error
            }"
            :disabled="submitted"
            @click="handleSubmit"
            >{{ buttonText }}</z-button
        >
    </div>
</template>

<script>
import ZSelect from "./ZSelect";
import ZInput from "./ZInput";
import BusinessLine from "@/constants/businessLine";
import ConversionChannel from "@/constants/conversionChannel";
import SalesChannel from "@/constants/salesChannel";
import Clients from "@/services/clients";
import trackActionWithAnalytics from "@/utils/track-Action-With-Analytics.js";

export default {
    name: "FulfillmentForm",

    components: { ZInput, ZSelect },

    data() {
        return {
            firstName: "",
            lastName: "",
            phone: "",
            companyName: "",
            companyType: null,
            avgDeliveries: null,
            avgDeliveriesOptions: ["50 a 100", "100 a 200", "200 a 300", "300 a 400", "400 a más"],
            website: "",
            conversionChannel: null,
            salesChannel: null,
            options: BusinessLine.options,
            conversionChannelOptions: ConversionChannel.options,
            salesChannelOptions: SalesChannel.options,
            submitted: false,
            loading: false,
            error: false,
            validEmail: null
        };
    },

    watch: {
        error: {
            handler() {
                if (this.error)
                    setTimeout(() => {
                        this.error = false;
                    }, 5000);
            }
        },

        companyType: {
            handler(newVal, oldVal) {
                if (oldVal === BusinessLine.NUM_ECOMMERCE) {
                    this.website = "";
                }
            }
        }
    },

    computed: {
        validFirstName() {
            return this.firstName.length > 0 ? true : this.error ? false : null;
        },

        validLastName() {
            return this.lastName.length > 0 ? true : this.error ? false : null;
        },

        validPhone() {
            return this.phone.length === 14 ? true : this.error ? false : null;
        },

        validCompanyName() {
            return this.companyName.length > 0 ? true : this.error ? false : null;
        },

        validateCompanyType() {
            return this.companyType !== null ? true : this.error ? false : null;
        },

        validateSalesChannel() {
            return this.salesChannel !== null ? true : this.error ? false : null;
        },

        validateConversionChannel() {
            return this.conversionChannel !== null ? true : this.error ? false : null;
        },

        validateAvgDeliveries() {
            return this.avgDeliveries !== null ? true : this.error ? false : null;
        },

        validWebsite() {
            if (this.website.length > 0) {
                return this.website.match(
                    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
                ) !== null
                    ? true
                    : this.error
                    ? false
                    : null;
            }
            return true;
        },

        buttonText() {
            return this.submitted
                ? "Enviado"
                : this.error
                ? "Revisa tu infromación"
                : "Registrarme";
        },
        isECommerce() {
            return this.companyType === BusinessLine.NUM_ECOMMERCE;
        }
    },

    methods: {
        handleSubmit() {
            if (this.validForm()) {
                this.loading = true;
                const request = {};
                request.firstName = this.firstName;
                request.lastName = this.lastName;
                request.phone = this.phone;
                request.companyName = this.companyName;
                request.companyType = BusinessLine.get[this.companyType];
                request.avgDeliveries = this.avgDeliveries;
                request.salesChannel = this.salesChannel;
                request.conversionChannel = this.conversionChannel;
                if (this.website.length > 0) request.website = this.website;
                Clients.newProspect(request)
                    .then(() => {
                        this.error = false;
                        this.submitted = true;
                        trackActionWithAnalytics.facebook("Lead", "contacto banner principal");
                        trackActionWithAnalytics.google("formulario", "contacto banner principal");
                    })
                    .catch(err => {
                        this.error = true;
                        this.$captureError(err);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        },

        validForm() {
            if (
                this.validFirstName &&
                this.validLastName &&
                this.validPhone &&
                this.validCompanyName &&
                this.validateCompanyType &&
                this.validateSalesChannel &&
                this.validateConversionChannel &&
                this.validateAvgDeliveries &&
                this.validWebsite
            )
                return true;
            this.error = true;
            return false;
        }
    }
};
</script>

<style lang="scss" scoped>
.fulfillment-form {
    text-align: center;
    margin: 0 auto;
    max-width: 500px;
    .form-input,
    .z-select {
        margin-top: 15px;
    }

    .form-button {
        height: 40px;
        border-radius: 10px;
        font-weight: 600;
    }

    .title {
        font-size: 1.5rem;
        font-weight: bold;
    }

    .submitted {
        background-color: $tropical-rain-forest;
        color: white;
    }

    .error {
        background: $medium-carmine;
    }

    @media (min-width: 1200px) {
        text-align: left;
        background: $solitude;
        box-shadow: 0 0 16px 1px rgba(0, 0, 0, 0.16);
        border-radius: 26px;
        padding: 3em;
    }
}
</style>
