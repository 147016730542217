const LOGISTICS = "LOGÍSTICA";
const SUCCESS = "CASOS DE ÉXITO";
const WEBINAR = "WEBINAR";

const NUM_LOGISTICS = 0;
const NUM_SUCCESS = 1;
const NUM_WEBINAR = 2;

const get = {};
get[NUM_LOGISTICS] = LOGISTICS;
get[NUM_SUCCESS] = SUCCESS;
get[NUM_WEBINAR] = WEBINAR;
get[LOGISTICS] = NUM_LOGISTICS;
get[SUCCESS] = NUM_SUCCESS;
get[WEBINAR] = NUM_WEBINAR;

const options = [
    { value: NUM_LOGISTICS, text: LOGISTICS },
    { value: NUM_SUCCESS, text: SUCCESS },
    { value: NUM_WEBINAR, text: WEBINAR }
];

export default {
    get,
    options,
    NUM_LOGISTICS,
    NUM_SUCCESS,
    NUM_WEBINAR
};
