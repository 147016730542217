<template>
    <div :class="['badge', `${classType}`]">
        {{ name }}
    </div>
</template>

<script>
import postType from "../constants/postType";

export default {
    name: "BlogPostBadge",

    props: {
        type: {
            type: Number,
            required: false,
            default: 1
        }
    },

    computed: {
        name() {
            return postType.get[this.type];
        },

        classType() {
            switch (parseInt(this.type, 10)) {
                case 0:
                    return "blue";
                case 1:
                    return "purple";
                case 2:
                    return "gray";
                default:
                    return "blue";
            }
        }
    }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables/colors.scss";

.badge {
    border-radius: 3px;
    color: white;
    font-size: 12px;

    &.blue {
        background-color: $smalt;
    }

    &.purple {
        background-color: $neon-blue;
    }

    &.gray {
        background-color: $nero;
    }
}
</style>
