<template>
    <div>
        <z-picture :img="img" className="fulfillment-service" />
        <div class="fulfillment-service-text">{{ title }}</div>
    </div>
</template>

<script>
import ZPicture from "./ZPicture";
export default {
    components: { ZPicture },
    name: "FulfillmentService",

    props: {
        title: {
            type: String,
            default: "title"
        },
        img: {
            type: String,
            require: true,
            default: "img-zf-01"
        }
    }
};
</script>

<style lang="scss" scoped>
.fulfillment-service-text {
    font-weight: 500;
    font-size: 16px;
}
</style>
