<template>
    <div class="hero">
        <div class="hero-text">
            <div class="container">
                <h1 class="font-weight-bold">
                    Zubut 360
                    <span class="font-weight-normal">Almacenaje, preparación y envíos</span>
                </h1>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "FulfillmentHero"
};
</script>

<style lang="scss" scoped>
.hero {
    width: 100%;
    height: 75vh;
    background-image: url("../assets/img/img-landing.jpg");
    object-fit: cover;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    color: white;

    .hero-text {
        display: flex;
        align-items: center;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.4);
    }

    h1 {
        font-size: 46px;
        text-align: center;
        span {
            font-size: 16px;
            display: block;
        }
    }

    .separate {
        color: $dodger-blue;
        font-weight: bold;
        font-size: 24px;
        padding-bottom: 20px;
        padding: 0 20px 20px 20px;
    }

    .countdown-title {
        padding-top: 10px;
        font-size: 1.2rem;
    }

    .countdown-number {
        font-size: 60px;
        font-weight: 800;
        width: 100px;
    }

    @include media-breakpoint-down(md) {
        .hero-countdown {
            transform: scale(0.7);
        }
    }

    @include media-breakpoint-down(sm) {
        .hero-countdown {
            transform: scale(0.5);
        }
    }
    @include media-breakpoint-up(sm) {
        h1 {
            span {
                font-size: 36px;
            }
        }
    }
    @media (min-width: 1200px) {
        h1 {
            text-align: left;
            max-width: 450px;
        }
        .container {
            max-width: 1400px;
        }
    }
}
</style>
